<template>
  <div class="myLive">
    <el-tabs v-model="active" @tab-click="handleClick">
      <el-tab-pane label="今日直播"></el-tab-pane>
      <el-tab-pane label="直播回放"></el-tab-pane>
      <el-tab-pane label="直播课程"></el-tab-pane>
    </el-tabs>
    <div v-if="contentIndex == '2'">
      <div v-if="ActiveList.length">
        <div v-for="(item, index) in ActiveList" :key="index">
          <div class="myClass_box">
            <div class="myClass_box_left">
              <div class="myClass_box_left_img">
                <img :src="item.coverPath" alt />
              </div>
              <div class="myClass_box_left_content">
                <p>{{ item.packageName }}</p>
                <!-- <p>
                  <span>到期时间：</span>
                  <span>{{ item.effectiveEtime }}</span>
                </p> -->
                
              </div>
            </div>
            <button
              class="myClass_box_right"
              @click="handleChange_abc(index, item.id)"
              style="cursor: pointer"
            >
              开始学习
            </button>
          </div>
          <el-collapse
            @change="handleChange(index, item.id)"
            v-model="activeNames"
            accordion
          >
            <el-collapse-item :name="index">
              <template slot="title">
                <!-- <div class="coll-bd"></div> -->
              </template>
              <div class="myClass_box_list">
                <div class="myClass_box_list_tips">
                  <span
                    :class="item.sltId == key.id ? 'active' : ''"
                    v-for="(key, value) in item.sltObj.types"
                    @click="onSlt(key, index, value)"
                    :key="value"
                    >{{ key.name }}</span
                  >
                </div>
                <!-- <div class="myClass_box_wire"></div> -->
                <div class="myClass_box_list_lessons">
                  <div
                    class="myClass_box_list_lessons_item"
                    v-for="(item2, index2) in item.sltObj.courseList"
                    :key="index2"
                  >
                    <div class="myClass_box_list_lessons_item_left">
                      <div class="myClass_box_list_lessons_item_left_top">
                        <img
                          src="../../assets/mySelf/my-live.png"
                          alt
                        
                        />
                        
                        <p>
                          {{ item2.name }}
                          <span v-if="item2.isLastStudy == 1">(上次学习)</span>
                        </p>
                      </div>
                     
                    </div>
                    <button
                      style="cursor: pointer"
                     
                      class="myClass_box_list_lessons_item_right"
                      @click="goLiveItemPage(item2)"
                    >
                      看直播
                    </button>
                    
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="noMore" v-else>
        <div class="coursediv_empty_img">
          <img src="@/assets/class_empty.png" alt="" />
        </div>
        暂无数据
      </div>
    </div>
    <div v-else>
      <div v-if="dataList.length">
        <div class="myLive_item" v-for="(item, index) in dataList" :key="index">
          <div class="myLive_item_headImg">
            <!-- <img :src="item.teacherHeaderPath" alt /> -->
            <span>{{ item.teacherName }}</span>
          </div>
          <div class="myLive_item_text">{{ item.name }}</div>
          <!-- <div class="myLive_item_bayType">{{ typeList[item.type-1] }}</div> -->
          <div class="myLive_item_liveType">
            <p>{{ item.liveTime }}</p>
            <p v-if="item.type == '1'">
              <img src="@/assets/home/live_icon.png" alt />
              <span>正在直播</span>
            </p>
            <p v-if="item.type == '4'">
              <!-- <img src="@/assets/home/live_icon.png" alt /> -->
              <span>待开播</span>
            </p>
          </div>
          <div class="myLive_item_operation">
            <button v-if="item.type == 1" @click="toLiveDetail(item)">
              进入直播
            </button>
            <button v-if="item.type == 5" @click="chakanhuifang(item,2)">
              查看回放
            </button>
          </div>
          <div class="myLive_item_position">{{ item.typeName }}</div>
        </div>
      </div>
      <div class="noMore" v-else>
        <div class="coursediv_empty_img">
          <img src="@/assets/class_empty.png" alt />
        </div>
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import {
  live_list,
  chakanhuifang,
  liveTodayList,
  livePlaybackList,
  coursepackageList,
  courseTypeList,
  liveCourseList,
} from "../../api/myself";
export default {
  name: "myLive",
  data() {
    return {
      active: 0,
      contentIndex: "0",
      dataList: [],
      activeNames: "",
      ActiveList: [],
      typeList: ["进入", "预约", "购买", "待开播", "回放"],
    };
  },
  mounted() {
    this.liveTodayList();
  },
  methods: {
    chakanhuifang(item) {
      let routeUrl = this.$router.resolve({
        path: "/videoPlayRoom",
        query: {
          channelId: item.channelId,
          id: item.id,
          liveType: item.liveType,
          courseId: item.courseId,
          coursePackageId: item.coursePackageId,
          title:item.name,
          state:'2'
        },
      });
      window.open(routeUrl.href, "_blank");
      // var obj={channelId:item.channelId};
      // chakanhuifang(obj).then(res => {
      //     console.log(res);

      //     // this.dataList = res.data.data;
      // })
    },
    handleClick(val) {
      this.contentIndex = val.index;
      //this.live_list();
      switch (val.index) {
        case "0":
          this.activeNames = "";
          this.liveTodayList();
          break;
        case "1":
          this.activeNames = "";
          this.livePlaybackList();
          break;
        case "2":
          this.coursepackageList();
          break;
      }
    },
    liveTodayList() {
      liveTodayList({ page: 0 }).then((res) => {
        if (res.data.code == 0) {
          this.dataList = res.data.data;
          //this.LoadingToday = false
        }
      });
    },
    livePlaybackList() {
      livePlaybackList({ page: 0 }).then((res) => {
        if (res.data.code == 0) {
          this.dataList = res.data.data;
        }
      });
    },
    //二级 title
    course_list(index, id, idx) {
      
      let obj = { coursePackageId: id };
      courseTypeList(obj).then((res) => {
        var course_list = res.data.data;
        for (var i = 0; i < course_list.length; i++) {
          course_list[i].coursePackageId = id;
        }
        this.ActiveList[index].sltId = course_list[index].id;
        this.TodayRefresh(id, course_list[index].id, index, course_list);
      });
    },
    //二级  list
    TodayRefresh(id, typeId, index, data) {
     
      let params = {
        coursePackageId: id,
        typeId: typeId,
      };
      liveCourseList(params).then((res) => {

        this.ActiveList[index].sltObj.types = data;
        this.ActiveList[index].sltObj.courseList = res.data.data;
     
      });
    },
    onSlt(item, index, idx) {
      
      this.ActiveList[index].sltId = item.id;
    
      let obj = { coursePackageId: item.coursePackageId };
      courseTypeList(obj).then((res) => {
        var course_list = res.data.data;
        for (var i = 0; i < course_list.length; i++) {
          course_list[i].coursePackageId = item.coursePackageId;
        }
       
        this.TodayRefresh(item.coursePackageId, item.id, index, course_list);
      });
    },
    coursepackageList() {
      coursepackageList({ page: 0 }).then((res) => {
        res.data.data.forEach((item) => {
          item.isShow = false;
          item.sltId = -1;
          item.sltObj = { courseList: [], types: [] };
        });
        this.ActiveList = res.data.data;
      });
    },
    goVideo(item) {
      var videoObj = {
        classHourId: item.recentlyStudyClassHourId,
        videoId: item.recentlyStudyClassHourVideoId,
        classHourName: item.name,
      };
      var clickItemObj = {
        courseId: item.id,
      };
      var classDetailObj = {
        isBuy: 1,
        courseName: "获取值",
      };
      //classDetail.isBuy
      let routeUrl = this.$router.resolve({
        path: "/videoRoom",
        query: {
          courseId: item.id,
          coursePackageId: item.coursePackageId,
          classHourId: item.recentlyStudyClassHourId,
          // videoObj:JSON.stringify(videoObj),
          // clickItem:JSON.stringify(clickItemObj),
          // classDetail:JSON.stringify(classDetailObj),
          studyTimeLength: item.recentlyStudyClassHourVideoWatchLength,
          // type:0
        },
      });
      localStorage.setItem(
        "videoRoom",
        JSON.stringify({
          videoObj: videoObj,
          clickItem: clickItemObj,
          classDetail: classDetailObj,
          coursePackageId: item.coursePackageId,
          studyTimeLength: item.recentlyStudyClassHourVideoWatchLength,
          type: 0,
        })
      );
      window.open(routeUrl.href, "_blank");
    },
    goLiveItemPage(item) {
      this.$router.push({
        path: "/myself/liveItem",
        query: {
          item: JSON.stringify(item),
        },
      });
    },
    handleChange(index, id) {
      let dataList = this.ActiveList;
      dataList[index].isShow = !dataList[index].isShow;
      if (dataList[index].isShow) {
        this.course_list(index, id);
      }
    },
    handleChange_abc(index, id) {
      let dataList = this.ActiveList;
      if (!dataList[index].isShow) {
        this.activeNames = index;
      } else {
        this.activeNames = "";
      }
      dataList[index].isShow = !dataList[index].isShow;
      if (dataList[index].isShow) {
        this.course_list(index, id);
      }
    },
    live_list() {
      live_list({ type: Number(this.active) + 1 }).then((res) => {
        this.dataList = res.data.data;
      });
    },
    // 去直播间
    toLiveDetail(item) {
      // var url='https://live.polyv.cn/watch/'+item.channelId
      // window.open(url, '_blank');
      let routeUrl = this.$router.resolve({
        path: "/videoPlayRoom",
        query: {
          channelId: item.channelId,
          id: item.id,
          liveType: item.liveType,
          courseId: item.courseId,
          coursePackageId: item.coursePackageId,
          title:item.name,
          state:1,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style>
.noMore {
  text-align: center;
  width: 100%;
  color: #999;
  font-size: 14px;
  margin: 40px 0;
}
</style>